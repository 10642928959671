<template lang="pug">
b-card(v-if="Object.keys(sailorDocument).length" header-tag="header")
  template(#header)
    div.flex-row-sb
      div.text-uppercase.text-left {{ $t('translationAgency') }} (ID: {{ sailorDocument.id }})
      div.documentIconControl
        ControlButtons(:controlButtons="controlButtons")

  TranslateAgencyInfo(v-if="sailorDocument.behavior.viewInfoBlock" :sailorDocument="sailorDocument")
  TranslateAgencyEdit(
    v-else-if="sailorDocument.behavior.viewEditBlock"
    :sailorDocument="sailorDocument"
    actionType="edit"
    @loadData="loadData")
v-progress-linear(v-else indeterminate)
</template>

<script>
import { back, deleteConfirmation, viewDetailedComponent } from '@/mixins/main'
import { mapActions } from 'vuex'

export default {
  components: {
    TranslateAgencyInfo: () => import('./Info'),
    TranslateAgencyEdit: () => import('./Form'),
    ControlButtons: () => import('@/components/atoms/ControlButtons')
  },
  data () {
    return {
      viewDetailedComponent,
      deleteConfirmation,
      back,
      sailorDocument: {},
      controlButtons: [
        {
          id: 1,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewInfoBlock'),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.info'
        },
        {
          id: 2,
          name: 'mdi-pen',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewEditBlock'),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.edit'
        },
        {
          id: 4,
          name: 'mdi-trash-can-outline',
          action: () => this.deleteTranslateCompany(),
          checkAccess: () => true,
          color: '#F73E07',
          tooltip: 'documentIconControl.delete'
        },
        {
          id: 5,
          name: 'mdi-close',
          action: () => back('translate-agencies-backoffice'),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.close'
        }
      ]
    }
  },
  computed: {
    documentID () { return this.$route.params.id }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    ...mapActions(['getTranslationAgency', 'removeTranslationAgency']),

    async loadData () {
      const response = await this.getTranslationAgency(this.documentID)
      if (response?.code === 200) this.sailorDocument = response.data
    },

    async deleteTranslateCompany () {
      const result = await deleteConfirmation()
      if (result) {
        const response = await this.removeTranslationAgency(this.documentID)
        if (response?.code === 204) {
          this.$notification.success('deletedDocument')
          back('translate-agencies-backoffice')
        }
      }
    }
  }
}
</script>
